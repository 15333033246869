@import url("https://fonts.googleapis.com/css?family=Kanit:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=thai");
@font-face {
  font-family: "supermarket";
  src: url("../../public/fonts/supermarket/supermarket.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
}

:root {
  --red-color: #e7314a;
  --primary-color: #014da3;
  --primary-dark-color: #1f76a9;
  --text-dark-color: #010e0f;
}

.font-supermarket {
  font-family: "supermarket";
}
.font-kanit {
  font-family: "Kanit";
}

/* global loading */
#global-loading {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  place-content: center;
}

[class^="ant-timeline"],
[class*=" ant-timeline"] {
  font-size: inherit;
}

.ant-btn.ant-btn-sm {
  border-radius: 0 !important;
  padding: 3px 12px;
  line-height: 22px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(25% - 18px) !important;
}
.ant-timeline.ant-timeline-label
  .ant-timeline-item-left
  .ant-timeline-item-content {
  inset-inline-start: calc(25% - 4px);
}
.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
  inset-inline-start: 25% !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  inset-inline-start: 25% !important;
}
.ant-timeline .ant-timeline-item-head {
  background-color: transparent !important;
}
/* 
div.ant-timeline div.ant-timeline-item-label {
  width: calc(30% - 12px) !important;
}

div.ant-timeline div.ant-timeline-item-content {
  left: calc(30% - 4px) !important;
  width: calc(30% - 4px) !important;
}

div.ant-timeline div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 30% !important;
} */

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-color: var(--red-color);
}

/* footer */
.footer-home-page {
  padding: 60px 0;
  display: block;
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-family: "supermarket";
  width: 100%;
  background-image: url("../../public/images/home-page/architecture.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.footer {
  padding: 15px 0;
  color: #fff;
  background-color: var(--primary-color);
  font-weight: 400;
}
.footer a {
  color: #fff;
}
/* footer */

/* sitemap */
.sitemap .title {
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--text-dark-color);
  display: inline;
  padding-bottom: 2px;
  border-bottom: 3px solid var(--primary-dark-color);
}
.sitemap a {
  color: var(--text-dark-color);
}
.sitemap a:hover {
  text-decoration: underline;
}
/* sitemap */
/* banner-product */
.banner-product .title {
  color: #505f6e;
  font-size: 28px;
  line-height: 1.8rem;
}
.banner-product .image-product-1 {
  background: url("../../public/images/home-page/content-04.jpg") no-repeat
    center;
}
.banner-product .image-product-1:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0px;
  top: 10%;
  width: 0;
  height: 0;
  border-left: 15px solid #f7f7f7;
  border-right: 15px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
.banner-product .image-product-2 {
  background: url("../../public/images/home-page/architecture.jpg") no-repeat;
}
.banner-product .image-product-2:after {
  content: " ";
  display: block;
  position: absolute;
  right: 0px;
  top: 10%;
  width: 0;
  height: 0;
  border-right: 15px solid #f7f7f7;
  border-left: 15px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
/* banner-product */

.color-blue {
  height: 250px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#214672+30,ffffff+70 */
  background: rgb(
    33,
    70,
    114
  ); /* Old browsers */ /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMyMTQ2NzIiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(33, 70, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, rgba(33, 70, 114, 1)),
    color-stop(70%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(33, 70, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(33, 70, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(33, 70, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(33, 70, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#214672', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}

.color-light-green {
  height: 250px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1b9080+30,ffffff+70 */
  background: rgb(
    27,
    144,
    128
  ); /* Old browsers */ /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMxYjkwODAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(27, 144, 128, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, rgba(27, 144, 128, 1)),
    color-stop(70%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(27, 144, 128, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(27, 144, 128, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(27, 144, 128, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(27, 144, 128, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b9080', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}

.color-bronze {
  height: 250px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#877f72+30,ffffff+70 */
  background: rgb(
    135,
    127,
    114
  ); /* Old browsers */ /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiM4NzdmNzIiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(135, 127, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, rgba(135, 127, 114, 1)),
    color-stop(70%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(135, 127, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(135, 127, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(135, 127, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(135, 127, 114, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#877f72', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}
.color-gray {
  height: 250px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#696e81+30,ffffff+70 */
  background: rgb(
    105,
    110,
    129
  ); /* Old browsers */ /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiM2OTZlODEiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(105, 110, 129, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, rgba(105, 110, 129, 1)),
    color-stop(70%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(105, 110, 129, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(105, 110, 129, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(105, 110, 129, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(105, 110, 129, 1) 30%,
    rgba(255, 255, 255, 1) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#696e81', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}
.color-light-blue {
  height: 250px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2e4162+30,cae6e9+70 */
  background: rgb(
    46,
    65,
    98
  ); /* Old browsers */ /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMyZTQxNjIiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MCUiIHN0b3AtY29sb3I9IiNjYWU2ZTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(46, 65, 98, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, rgba(46, 65, 98, 1)),
    color-stop(70%, rgba(202, 230, 233, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(46, 65, 98, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(46, 65, 98, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(46, 65, 98, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(46, 65, 98, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e4162', endColorstr='#cae6e9',GradientType=0 ); /* IE6-8 */
}
.color-light-green {
  height: 250px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#198c89+30,cae6e9+70 */
  background: rgb(
    25,
    140,
    137
  ); /* Old browsers */ /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMxOThjODkiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MCUiIHN0b3AtY29sb3I9IiNjYWU2ZTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(25, 140, 137, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, rgba(25, 140, 137, 1)),
    color-stop(70%, rgba(202, 230, 233, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(25, 140, 137, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(25, 140, 137, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(25, 140, 137, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(25, 140, 137, 1) 30%,
    rgba(202, 230, 233, 1) 70%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#198c89', endColorstr='#cae6e9',GradientType=0 ); /* IE6-8 */
}

#cc-main {
  --cc-font-family: Noto Sans Thai;
  --cc-modal-border-radius: 0.5rem;
  --cc-btn-border-radius: 0.4rem;
  --cc-modal-transition-duration: 0.25s;
  --cc-modal-margin: 1rem;
  --cc-z-index: 2147483647;
}
